import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Svg } from './ui';
import stars from '../images/stars.inline.svg';

const StyledTestimonial = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.figure`
  margin: 0 auto;
  max-width: 680px;
  text-align: center;

  &:before {
    content: '“';
    display: inline-block;
    height: 84px;
    width: 84px;
    color: ${standardColours.white};
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
    ${fontSize(70)};
    line-height: 105px;
    background-color: ${brandColours.secondary};
    border-radius: 50%;
  }
`;

const StyledBlockquote = styled.blockquote`
  margin: 0;
`;

const StyledStars = styled(Svg)`
  margin: 20px 0;
`;

const StyledQuote = styled.p`
  font-weight: ${fontWeights.semibold};
  ${fontSize(20)};
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledAuthor = styled.figcaption`
  margin-top: 25px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 35px;
  `}
`;

const Testimonial = ({ quote, author }) => (
  <StyledTestimonial>
    <Container>
      <StyledInner>
        <StyledBlockquote>
          <StyledStars image={stars} />
          <StyledQuote>{quote}</StyledQuote>
        </StyledBlockquote>
        <StyledAuthor>{author}</StyledAuthor>
      </StyledInner>
    </Container>
  </StyledTestimonial>
);

export default Testimonial;
