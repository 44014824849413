import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Header } from './ui';

const StyledStatistics = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.tertiary};
`;

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const StyledItem = styled.div`
  margin: 15px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin: 20px;
  `}

  ${minBreakpointQuery.large`
    margin: 25px;
  `}
`;

const StyledNumber = styled.span`
  display: inline-block;
  padding: 10px;
  height: 110px;
  width: 110px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  ${fontSize(20)};
  line-height: 90px;
  background-color: ${brandColours.primary};
  border-radius: 50%;

  ${minBreakpointQuery.small`
    height: 120px;
    width: 120px;
    ${fontSize(22)};
    line-height: 100px;
  `}

  ${minBreakpointQuery.large`
    height: 130px;
    width: 130px;
    ${fontSize(24)};
    line-height: 110px;
  `}
`;

const StyledDescription = styled.p`
  margin-top: 12px;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    margin-top: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}
`;

const Statistics = ({ heading, text, items }) => (
  <StyledStatistics>
    <Container>
      <Header heading={heading} text={text} />
      <StyledItems>
        {items.map(({ id, number, description }) => (
          <StyledItem key={id}>
            <StyledNumber>{number}</StyledNumber>
            <StyledDescription>{description}</StyledDescription>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledStatistics>
);

export default Statistics;
