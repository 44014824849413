import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  commonFieldStyles,
  visuallyHidden,
} from '../styles';
import { Container, Heading, Button } from './ui';

const StyledDownloadForm = styled.section`
  position: relative;
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  padding: 30px;
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.medium`
    padding: 40px;
  `}
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;
  text-align: center;

  ${minBreakpointQuery.medium`
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledHeading = styled(Heading)`
  color: ${standardColours.white};
`;

const StyledText = styled.p`
  margin-top: 10px;
  color: ${standardColours.white};
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: ${({ emailContact }) =>
      emailContact ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'};
  `}
`;

const StyledLabel = styled.label`
  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}
`;

const StyledLabelText = styled.span`
  margin: 8px;
  color: ${standardColours.white};
  ${fontSize(12)};

  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}

  a {
    color: ${brandColours.tertiary};
  }
`;

const StyledInput = styled.input`
  ${commonFieldStyles()}
`;

const StyledButton = styled(Button)`
  width: 100%;

  ${minBreakpointQuery.smedium`
    grid-column: span 2;
    align-self: center;
  `}

  ${({ emailContact }) => {
    if (!emailContact) {
      return `
        margin: 0 auto;
        max-width: 220px;
      `;
    }
  }}
`;

const StyledFileWrapper = styled.div`
  padding: 30px;
  text-align: center;
  border: 1px solid ${brandColours.tertiary};
`;

const StyledFileText = styled.p`
  color: ${standardColours.white};
`;

const StyledFileLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.medium};
  ${fontSize(18)}
  border-bottom: 1px solid ${standardColours.white};
`;

const DownloadForm = ({
  heading,
  text,
  file: { title, basename, url },
  emailContact,
}) => {
  const [showDownload, setShowDownload] = useState(false);
  const anchorId = heading.toLowerCase().replace(/\s+/g, '-');

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById('download-form');
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setShowDownload(true))
      .catch(error => alert(error));
  };

  return (
    <StyledDownloadForm id={anchorId}>
      <Container>
        <StyledInner>
          <StyledHeader>
            <StyledHeading>{heading}</StyledHeading>
            {text && <StyledText>{text}</StyledText>}
          </StyledHeader>
          {!showDownload ? (
            <StyledForm
              id="download-form"
              name="download"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              emailContact={emailContact}
              onSubmit={e => handleSubmit(e)}
            >
              <input type="hidden" name="form-name" value="download" />
              <input type="hidden" name="subject" value={heading} />
              <StyledLabel>
                <StyledLabelText hide="true">Name</StyledLabelText>
                <StyledInput
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                <StyledLabelText hide="true">Company Name</StyledLabelText>
                <StyledInput
                  type="text"
                  name="company-name"
                  placeholder="Company Name"
                  required
                />
              </StyledLabel>
              {emailContact && (
                <>
                  <StyledLabel>
                    <StyledLabelText hide="true">Email</StyledLabelText>
                    <StyledInput
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </StyledLabel>
                  <StyledLabel>
                    <input
                      type="checkbox"
                      name="marketing-consent"
                      value="true"
                      required
                    />
                    <StyledLabelText>
                      I consent to Impact Score using my contact details to send
                      me this free pdf and other marketing activities.
                    </StyledLabelText>
                  </StyledLabel>
                </>
              )}

              <StyledLabel hide="true">
                <StyledLabelText>
                  Don't fill this out if you're human
                </StyledLabelText>
                <input name="bot-field" />
              </StyledLabel>
              <StyledButton emailContact={emailContact}>Submit</StyledButton>
            </StyledForm>
          ) : (
            <StyledFileWrapper>
              {title && (
                <StyledFileText>
                  {emailContact
                    ? `Thank you for your details - we will email the '${title}' to you shortly.`
                    : `Thanks, you can download '${title}' using the link below.`}
                </StyledFileText>
              )}
              {!emailContact && (
                <StyledFileLink
                  id={basename}
                  href={url}
                  target="_blank"
                  rel="noopener"
                  download
                >
                  Download File
                </StyledFileLink>
              )}
            </StyledFileWrapper>
          )}
        </StyledInner>
      </Container>
    </StyledDownloadForm>
  );
};

export default DownloadForm;
