import React from 'react';
import { graphql } from 'gatsby';
import Accordion from './Accordion';
import ContactForm from './ContactForm';
import ContainedImage from './ContainedImage';
import Content from './Content';
import DownloadForm from './DownloadForm';
import ExternalVideo from './ExternalVideo';
import FeaturedLogos from './FeaturedLogos';
import FullWidthImage from './FullWidthImage';
import ImageContent from './ImageContent';
import InternalVideo from './InternalVideo';
import Statistics from './Statistics';
import Tabs from './Tabs';
import TeamProfiles from './TeamProfiles';
import Testimonial from './Testimonial';

const ModularBlocks = ({ items }) =>
  items.map(item => (
    <React.Fragment key={item.id}>
      {item.model.apiKey === 'accordion_modular_block' && (
        <Accordion
          heading={item.instance.heading}
          items={item.instance.accordionItems}
        />
      )}
      {item.model.apiKey === 'contact_form_modular_block' && (
        <ContactForm
          heading={item.heading}
          text={item.text}
          formHeading={item.formHeading}
          formButtonText={item.formButtonText}
        />
      )}
      {item.model.apiKey === 'contained_image_modular_block' && (
        <ContainedImage image={item.image} />
      )}
      {item.model.apiKey === 'content_modular_block' && (
        <Content
          html={item.content}
          contain={item.contain}
          twoColumns={item.twoColumns}
          highlight={item.highlight}
        />
      )}
      {item.model.apiKey === 'download_form_modular_block' && (
        <DownloadForm
          heading={item.heading}
          text={item.text}
          file={item.file}
          emailContact={item.emailContact}
        />
      )}
      {item.model.apiKey === 'external_video_modular_block' && (
        <ExternalVideo
          heading={item.heading}
          text={item.text}
          video={item.externalVideo}
        />
      )}
      {item.model.apiKey === 'featured_logos_modular_block' && (
        <FeaturedLogos
          heading={item.heading}
          text={item.text}
          items={item.logos}
        />
      )}
      {item.model.apiKey === 'full_width_image_modular_block' && (
        <FullWidthImage image={item.image} />
      )}
      {item.model.apiKey === 'image_content_modular_block' && (
        <ImageContent
          image={item.image}
          heading={item.heading}
          text={item.text}
          addAppLinks={item.addAppLinks}
          flip={item.flip}
        />
      )}
      {item.model.apiKey === 'internal_video_modular_block' && (
        <InternalVideo
          heading={item.heading}
          text={item.text}
          video={item.internalVideo}
        />
      )}
      {item.model.apiKey === 'statistics_modular_block' && (
        <Statistics
          heading={item.instance.heading}
          text={item.instance.text}
          items={item.instance.statistics}
        />
      )}
      {item.model.apiKey === 'tabs_modular_block' && (
        <Tabs heading={item.instance.heading} items={item.instance.tabs} />
      )}
      {item.model.apiKey === 'team_profiles_modular_block' && (
        <TeamProfiles
          heading={item.instance.heading}
          text={item.instance.text}
          items={item.instance.teamProfiles}
        />
      )}
      {item.model.apiKey === 'testimonial_modular_block' && (
        <Testimonial quote={item.quote} author={item.author} />
      )}
    </React.Fragment>
  ));

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      accordionItems {
        id
        heading
        content
      }
    }
  }

  fragment ContactFormModularBlockFragment on DatoCmsContactFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    formHeading
    formButtonText
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 1140)
      alt
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    twoColumns
    highlight
    contain
  }

  fragment DownloadFormModularBlockFragment on DatoCmsDownloadFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    file {
      title
      basename
      url
    }
    emailContact
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    externalVideo {
      height
      width
      provider
      providerUid
      url
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    logos {
      gatsbyImageData(width: 100)
      alt
    }
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 2000, layout: FULL_WIDTH)
      alt
    }
  }

  fragment ImageContentModularBlockFragment on DatoCmsImageContentModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 700, height: 500)
      alt
    }
    heading
    text
    addAppLinks
    flip
  }

  fragment InternalVideoModularBlockFragment on DatoCmsInternalVideoModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    internalVideo {
      format
      url
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }

  fragment StatisticsModularBlockFragment on DatoCmsStatisticsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      text
      statistics {
        id
        number
        description
      }
    }
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      tabs {
        id
        heading
        content
      }
    }
  }

  fragment TeamProfilesModularBlockFragment on DatoCmsTeamProfilesModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      text
      teamProfiles {
        id
        image {
          gatsbyImageData(width: 300, height: 300)
          alt
        }
        name
        role
        linkedinProfile
      }
    }
  }

  fragment TestimonialModularBlockFragment on DatoCmsTestimonialModularBlock {
    id
    model {
      apiKey
    }
    quote
    author
  }
`;
