import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAccordion = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.tertiary};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledItem = styled.article`
  margin: 20px 0;
  padding: 20px 30px;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.small`
    padding: 25px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 30px 50px;
  `}
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  font-weight: ${fontWeights.bold};
  ${fontSize(20)};
  line-height: 1.5;
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-right: 35px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    ${fontSize(24)};
  `}

  &:after {
    content: ${({ display }) => (display ? "'-'" : "'+'")};
    position: absolute;
    top: 50%;
    right: 0;
    color: ${brandColours.primary};
    ${fontSize(30)};
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(35)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(40)};
    `}
  }
`;

const StyledContent = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const Accordion = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledAccordion>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        {items.map(({ heading, content }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem>
              <StyledSubHeading
                display={display}
                onClick={() => setActiveItem(display ? undefined : i)}
              >
                {heading}
              </StyledSubHeading>
              <StyledContent html={content} display={display} />
            </StyledItem>
          );
        })}
      </Container>
    </StyledAccordion>
  );
};

export default Accordion;
