import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Header, Svg } from './ui';
import linkedinIcon from '../images/linkedin-icon.inline.svg';

const StyledTeamProfiles = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled(Header)`
  margin-left: 0;
  max-width: 480px;
  text-align: left;
`;

const StyledItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    gap: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const StyledItem = styled.article``;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const StyledNameRole = styled.div`
  margin-right: 10px;
`;

const StyledName = styled.h3`
  font-weight: ${fontWeights.bold};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledRole = styled.p`
  margin-top: 8px;
  ${fontSize(14)};
`;

const StyledLinkedin = styled.a`
  svg {
    height: 24px;
    width: 24px;
    fill: ${brandColours.secondary};
  }

  span {
    ${visuallyHidden()};
  }
`;

const TeamProfiles = ({ heading, text, items }) => (
  <StyledTeamProfiles>
    <Container>
      <StyledHeader heading={heading} text={text} />
      <StyledItems>
        {items.map(
          ({
            id,
            image: { gatsbyImageData, alt },
            name,
            role,
            linkedinProfile,
          }) => (
            <StyledItem key={id}>
              <GatsbyImage image={gatsbyImageData} alt={alt} />
              <StyledContent>
                <StyledNameRole>
                  <StyledName>{name}</StyledName>
                  <StyledRole>{role}</StyledRole>
                </StyledNameRole>
                {linkedinProfile && (
                  <StyledLinkedin
                    href={linkedinProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Svg image={linkedinIcon} />
                    <span>LinkedIn Profile</span>
                  </StyledLinkedin>
                )}
              </StyledContent>
            </StyledItem>
          )
        )}
      </StyledItems>
    </Container>
  </StyledTeamProfiles>
);

export default TeamProfiles;
