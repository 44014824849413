import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: {
      seoMetaTags,
      title,
      bannerText,
      bannerLinkUrl,
      bannerLinkText,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        bannerLinkUrl
        bannerLinkText
        modularBlocks {
          ...AccordionModularBlockFragment
          ...ContactFormModularBlockFragment
          ...ContainedImageModularBlockFragment
          ...ContentModularBlockFragment
          ...DownloadFormModularBlockFragment
          ...ExternalVideoModularBlockFragment
          ...FeaturedLogosModularBlockFragment
          ...FullWidthImageModularBlockFragment
          ...ImageContentModularBlockFragment
          ...InternalVideoModularBlockFragment
          ...StatisticsModularBlockFragment
          ...TabsModularBlockFragment
          ...TeamProfilesModularBlockFragment
          ...TestimonialModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          text={bannerText}
          link={{ url: bannerLinkUrl, text: bannerLinkText }}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
