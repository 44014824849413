import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, HtmlContent } from './ui';
import logo from '../images/logo.svg';
import gikiLogo from '../images/giki-logo.svg';
import googlePlayStore from '../images/google-play-store.svg';
import appleAppStore from '../images/apple-app-store.svg';

const StyledImageContent = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.smedium`
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 120px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.smedium`
          grid-row: 1;
          grid-column: 2;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div``;

const StyledText = styled(HtmlContent)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledApps = styled.div`
  margin-top: 15px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const StyledApp = styled.div`
  margin-top: 15px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledAppLink = styled.a`
  display: inline-block;
  margin: 5px 15px 5px 0;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledAppLogo = styled.img`
  width: 120px;
`;

const ImageContent = ({
  image: { gatsbyImageData, alt },
  heading,
  text,
  addAppLinks,
  flip,
}) => (
  <StyledImageContent>
    <Container>
      <StyledInner>
        <StyledImage image={gatsbyImageData} alt={alt} flip={flip} />
        <StyledContent>
          <Heading>{heading}</Heading>
          {text && <StyledText html={text} />}
          {addAppLinks && (
            <StyledApps>
              <StyledApp>
                <StyledAppLink
                  href="https://www.impactscore.app"
                  target="_blank"
                  rel="noopener"
                >
                  <StyledAppLogo src={logo} alt="Impact Score" />
                </StyledAppLink>
                <StyledAppLink
                  href="https://play.google.com/store/apps/details?id=app.impactscore.prod.twa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledAppLogo
                    src={googlePlayStore}
                    alt="Impact Score on Google Play Store"
                  />
                </StyledAppLink>
                <StyledAppLink
                  href="https://apps.apple.com/us/app/impact-score/id1535915728"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledAppLogo
                    src={appleAppStore}
                    alt="Impact Score on Apple App Store"
                  />
                </StyledAppLink>
              </StyledApp>
              <StyledApp>
                <StyledAppLink
                  href="https://products.impactscore.app"
                  target="_blank"
                  rel="noopener"
                >
                  <StyledAppLogo src={gikiLogo} alt="giki." />
                </StyledAppLink>
                <StyledAppLink
                  href="https://play.google.com/store/apps/details?id=com.gikibadges"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledAppLogo
                    src={googlePlayStore}
                    alt="Impact Score on Google Play Store"
                  />
                </StyledAppLink>
                <StyledAppLink
                  href="https://apps.apple.com/gb/app/giki-badges/id1377597780"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledAppLogo
                    src={appleAppStore}
                    alt="Impact Score on Apple App Store"
                  />
                </StyledAppLink>
              </StyledApp>
            </StyledApps>
          )}
        </StyledContent>
      </StyledInner>
    </Container>
  </StyledImageContent>
);

export default ImageContent;
