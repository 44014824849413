import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container, Header } from './ui';

const StyledFeaturedLogos = styled.section`
  ${sectionMargins()};
`;

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

const StyledItem = styled.div`
  margin: 10px;
`;

const FeaturedLogos = ({ heading, text, items }) => (
  <StyledFeaturedLogos>
    <Container>
      <Header heading={heading} text={text} />
      <StyledItems>
        {items.map(({ gatsbyImageData, alt }) => (
          <StyledItem>
            <GatsbyImage image={gatsbyImageData} alt={alt} />
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledFeaturedLogos>
);

export default FeaturedLogos;
