import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  sectionMargins,
  minBreakpointQuery,
  brandColours,
  standardColours,
  fontSize,
  commonFieldStyles,
  visuallyHidden,
} from '../styles';
import { Container, Header, Heading, Button } from './ui';

const StyledContactForm = styled.section`
  ${sectionMargins()}
`;

const StyledInner = styled.div`
  display: grid;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 120px;
  `}
`;

const StyledHeader = styled(Header)`
  ${minBreakpointQuery.smedium`
    text-align: left;
  `}
`;

const StyledForm = styled.div`
  background-color: ${brandColours.secondary};
  color: ${standardColours.white};
  padding: 30px;

  ${minBreakpointQuery.medium`
    padding: 40px;
  `}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
`;

const StyledFormInner = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

const StyledLabel = styled.label`
  ${({ honeypot, full }) => {
    if (honeypot) {
      return css`
        ${visuallyHidden()};
      `;
    } else if (full) {
      return css`
        grid-column: 1 / 3;
      `;
    }
  }}
`;

const StyledLabelText = styled.span`
  display: block;
  margin-bottom: 4px;
  ${fontSize(14)};
`;

const StyledInput = styled.input`
  ${commonFieldStyles()};
  height: 48px;
`;

const StyledTextarea = styled.textarea`
  ${commonFieldStyles()};
  height: 146px;
  resize: none;
`;

const StyledSubmit = styled(Button)`
  grid-column: 1 / 3;
  width: 100%;
`;

const StyledText = styled.p`
  grid-column: 1 / 3;
  color: ${standardColours.white};
  ${fontSize(14)};
  line-height: 1.7;

  a {
    text-decoration: underline;
  }
`;

const ContactForm = ({ heading, text, formHeading, formButtonText }) => {
  const {
    datoCmsFooter: { privacyPolicyLink },
  } = useStaticQuery(graphql`
    query ContactFormQuery {
      datoCmsFooter {
        privacyPolicyLink
      }
    }
  `);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById('contact-form');
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch(error => alert(error));
  };

  return (
    <StyledContactForm id="contact">
      <Container>
        <StyledInner>
          <StyledHeader heading={heading} text={text} />
          <StyledForm>
            <StyledHeading>{formHeading || 'Send us an email'}</StyledHeading>
            {!formSubmitted ? (
              <StyledFormInner
                id="contact-form"
                name="contact-form"
                method="POST"
                netlify-honeypot="question"
                data-netlify="true"
                onSubmit={e => handleSubmit(e)}
              >
                <input type="hidden" name="form-name" value="contact-form" />
                <input type="hidden" name="subject" value={heading} />
                <StyledLabel honeypot={true}>
                  <StyledLabelText>Question</StyledLabelText>
                  <StyledInput type="text" name="question" />
                </StyledLabel>
                <StyledLabel full={true}>
                  <StyledInput
                    type="text"
                    name="name"
                    placeholder="Name"
                    required
                  />
                </StyledLabel>
                <StyledLabel full={true}>
                  <StyledInput
                    type="email"
                    name="email"
                    placeholder="Email address"
                    required
                  />
                </StyledLabel>
                <StyledLabel full={true}>
                  <StyledInput
                    type="company-name"
                    name="company-name"
                    placeholder="Company name"
                    required
                  />
                </StyledLabel>
                <StyledLabel full={true}>
                  <StyledTextarea name="message" placeholder="Message" />
                </StyledLabel>
                <StyledSubmit type="submit">
                  {formButtonText || 'Send message'}
                </StyledSubmit>
                <StyledText>
                  By submitting your details, you agree to our{' '}
                  <a href={privacyPolicyLink} target="_blank" rel="noopener">
                    Privacy Policy
                  </a>
                  .
                </StyledText>
              </StyledFormInner>
            ) : (
              <StyledText>
                Congrats on kickstarting your sustainability journey! We'll get
                back to you asap
              </StyledText>
            )}
          </StyledForm>
        </StyledInner>
      </Container>
    </StyledContactForm>
  );
};

export default ContactForm;
